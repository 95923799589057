(function () {'use strict';}());
angular.module("app").directive('homeView', function($document,$timeout, AppService) {
    return {
      restrict: 'A',
      templateUrl: 'views/home.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        $scope.userHasSpun = false;

        $scope.getSimulationsState = function() {
          //if(1) return "unlocked";
          if(!$scope.spinnerSource) return "locked";

          var tier1 = $scope.spinnerSource.filter(filterTier,{tier:1});
          if(tier1.filter(filterTierComplete).filter(filterFunfacts).length >= 5) {
            return "unlocked";
          }
          return "locked";
        };

        $scope.setUserHasSpun = function(value) {
          $scope.userHasSpun = value;
        };

        $scope.renderAlert = function() {
          if($scope.language == "en")
          {
            $scope.setCurrentAlert("Play more games and quizzes to unlock this section.");
          }
          else{
            $scope.setCurrentAlert("Juega más juegos y cuestionarios para desbloquear esta sección."); //CONFIRM SPANISH TRANSLATION
          }
        };

        function filterTier(e) {
          if(e.tier === this.tier) return true;
          return false;
        }

        function filterFunfacts(e) {
          if(e.type === "funfact") return false;
          return true;
        }

        function filterTierComplete(e) {
          if(e.complete === true) return true;
          return false;
        }

      }
    };
  });