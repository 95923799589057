(function () {'use strict';}());
angular.module("app").directive('brainSim', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/brain-sim/brain-sim.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        var timeout;
        var ITEMS_STANDARD = $scope.brainSimStandard;
        var ITEMS_RESKIN = $scope.brainSimReskin;
        var $dropped = null, $target = null;
        var emotions = ['anxious','agitated','happy','sad'];
        var defaultEmotion;
        $scope.items = ITEMS_STANDARD;
        $scope.brainSim = attr.brainSim;
        $scope.item = $scope.items[0];

        if(attr.brainSim === "2") setDefaultEmotion();
        else $scope.currentReaction = "meh";

        $scope.done = false;
        $scope.started = false;

        $scope.introTextOptions = {
            // brain_sim_intro_1
            1: $scope.textLookup.brain_sim_intro_2,
            // brain_sim_intro_2
            2: $scope.textLookup.brain_sim_intro_1
        };

        $scope.completionTextOptions = {
            // brain_sim_complete_1
            1: $scope.textLookup.brain_sim_complete_2,
            // brain_sim_complete_2
            2: $scope.textLookup.brain_sim_complete_1
        };

        // $scope.introText = introTextOptions[attr.brainSim][$scope.language];
        // $scope.completionText = completionTextOptions[attr.brainSim][$scope.language];

       // $scope.start = function() {
        	$target = $element.find(".drop-container");

            $timeout(function(){

        	$(".item").draggable({revert: "invalid",start:function(){
                $scope.started = true;
                $scope.$apply();
            }});
        	$(".img.droppable").droppable({
                accept: ".item",
        		drop: function(e,ui) {
        			var item;

        			if($dropped) {
                        $dropped.removeClass("dropped hovering");
        				$dropped.css("left",0).css("top",0).css("opacity",1);
        			}

        			$dropped = $(ui.draggable);
                    $dropped.addClass("dropped").css("opacity",0);
                    $scope.playAudio("drop");
                    $scope.playAudioLoop("brain_"+$dropped.data("reaction"));
        			calculateOffset();
        			$scope.$apply(function(){
        				$scope.currentType = $dropped.data("type");
        				$scope.currentReaction = $dropped.data("reaction");
                        if(attr.brainSim == 1)
                        {
                            $scope.currentReactionText = fetchReactionText();
                            $scope.currentReactionVO = fetchReactionVO();
                        }
                        else{
                            $scope.currentReactionText = fetchReactionText2();
                            $scope.currentReactionVO = fetchReactionVO2();
                        }
        				
        				item = fetchItem();
        				item.dropped = true;
        				checkComplete();
        			});
        			//$dropped.css("left","-78px").css("top","155px");
                    $scope.updateState();
        		}, over: function(event,ui) {
                    var $active = $(ui.draggable);
                    $active.addClass("hovering");
                }, out: function(event,ui) {
                    var $active = $(ui.draggable);
                    $active.removeClass("hovering");
                }, hoverClass: "ui-state-hover"
        	});
        },10);


        $scope.getCurrentReactionText = function(){
            var feedback = "";
            if($scope.done)
            {
                feedback += ("<p class=\"p\">" + getText() + "\<br><br>" + $scope.completionTextOptions[attr.brainSim][$scope.language] + "</p>");
            }
            else{
                feedback += ("<p class=\"p\">" + getText() + "</p>");
            }
            return feedback;
        };

        $scope.getCurrentReactionVO = function(){
            var feedback = "";
            feedback += ("<button type=\"button\" class=\"audio-play\" data-click-audio=\"" + getVO() +"\" data-auto-audio=\""+ getVO() + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
            return feedback;
        };

        $scope.complete = function() {
            $scope.clear();
            $scope.items.forEach(function(e){
                e.dropped = false;
            });
			$scope.economy.coping += 3;
			$scope.setCurrentGame(null,"myth-busting",3);
            $scope.$emit("closeLoops");
        };

        $scope.clear = function() {
        	if($dropped) {
				$dropped.css("left",0).css("top",0).css("opacity",1);
                $dropped.removeClass("dropped hovering");
			}
			$dropped = null;
			$scope.currentType = null;
			$scope.currentReaction = null;
			$scope.currentReactionText = null;
            $scope.currentReactionVO = null;


            if(attr.brainSim === "2") $scope.currentReaction = defaultEmotion;
            else $scope.currentReaction = "meh";

            $scope.stopAudio();
            $scope.updateState();

        };

        $scope.replay = function() {
        	$scope.clear();
            if(attr.brainSim === "2") setDefaultEmotion();
        	$scope.items.forEach(function(e){
        		e.dropped = false;
        	});
        	$scope.done = false;
            $scope.$emit("closeLoops");
            $scope.updateState();
        };

        function checkComplete() {
        	for(var i = 0, count = $scope.items.length; i < count; i++) {
        		var item = $scope.items[i];
        		if(!item.dropped) return;
        	}
        	$scope.done = true;
        }

        function calculateOffset() {
        	var toffset = $target.offset();
        	var doffset = $dropped.offset();
        	var dposition = $dropped.position();

        	var dY = toffset.top - doffset.top;
        	var dX = toffset.left - doffset.left;

        	$dropped.css("top",(dposition.top + dY)+"px").css("left",(dposition.left + dX)+"px");
        }


        function fetchItem() {
        	for(var i = 0, count = $scope.items.length; i < count; i++) {
        		var item = $scope.items[i];
        		if(item.type === $scope.currentType) return item;
        	}
        	return null;
        }

        function fetchReactionText() {
            for(var i = 0, count = $scope.items.length; i < count; i++) {
                var item = $scope.items[i];
                $scope.item = item;
                if(item.type !== $scope.currentType) continue;
                $scope.randomNum = Math.random();
                var random = $scope.randomNum < 0.5 ? ("text1_" + $scope.language) : ("text2_" + $scope.language);
                return item[random];
            }
            return null;
        }

        function fetchReactionVO() {
            for(var i = 0, count = $scope.items.length; i < count; i++) {
                var item = $scope.items[i];
                $scope.item = item;
                if(item.type !== $scope.currentType) continue;
                var random = "";
                if(!$scope.done) {
                    random = $scope.randomNum < 0.5 ? ("vo1_" + $scope.language) : ("vo2_" + $scope.language);
                }
                else
                {
                    random = $scope.randomNum < 0.5 ? ("vo1_complete_" + $scope.language) : ("vo2_complete_" + $scope.language);
                }
                return item[random];
            }
            return null;
        }

        function getText() {
            var currItem = "";
            if(attr.brainSim == 1){
                currItem = $scope.randomNum < 0.5 ? ("text1_" + $scope.language) : ("text2_" + $scope.language);
                var text1 = $scope.item[currItem];
                return text1;
            }
            else{
                switch (true){
                    case ($scope.randomNum >= 0 && $scope.randomNum < 0.25):
                            currItem = ("anxious_text_" + $scope.language);                            
                        break;
                    case ($scope.randomNum >= 0.25 && $scope.randomNum < 0.5):
                            currItem = ("agitated_text_" + $scope.language);
                        break;
                    case ($scope.randomNum >= 0.5 && $scope.randomNum < 0.75):
                            currItem = ("happy_text_" + $scope.language);
                        break;
                    case ($scope.randomNum >= 0.75 && $scope.randomNum < 1):
                            currItem = ("sad_text_" + $scope.language);
                        break;
                }
                var text2 = $scope.item[currItem];
                return text2;
            }
        }

        function getVO() {
            var currItem = "";
            if(attr.brainSim == 1){
                if (!$scope.done)
                {
                    currItem = $scope.randomNum < 0.5 ? ("vo1_" + $scope.language) : ("vo2_" + $scope.language);
                } else {
                    currItem = $scope.randomNum < 0.5 ? ("vo1_complete_" + $scope.language) : ("vo2_complete_" + $scope.language);
                }
                var vo1 = $scope.item[currItem];
                return vo1;
            }
            else{
                switch (true){
                    case ($scope.randomNum >= 0 && $scope.randomNum < 0.25):
                        if(!$scope.done){
                            currItem = ("anxious_VO_" + $scope.language);
                        } else{
                            currItem = ("anxious_VO_complete_" + $scope.language); 
                        }
                        break;
                    case ($scope.randomNum >= 0.25 && $scope.randomNum < 0.5):
                        if(!$scope.done){
                            currItem = ("agitated_VO_" + $scope.language);
                        } else{
                            currItem = ("agitated_VO_complete_" + $scope.language);
                        }
                        break;
                    case ($scope.randomNum >= 0.5 && $scope.randomNum < 0.75):
                        if(!$scope.done){ 
                            currItem = ("happy_VO_" + $scope.language);
                        } else {
                            currItem = ("happy_VO_complete_" + $scope.language);
                        }
                        break;
                    case ($scope.randomNum >= 0.75 && $scope.randomNum < 1):
                        if(!$scope.done){
                            currItem = ("sad_VO_" + $scope.language);
                        } else {
                            currItem = ("sad_VO_complete_" + $scope.language);
                        }
                        break;
                }
                var vo2 = $scope.item[currItem];
                return vo2;
            }
        }

        function fetchReactionText2() {
            for(var i = 0, count = $scope.items.length; i < count; i++) {
                var item = $scope.items[i];
                $scope.item = item;
                if(item.type !== $scope.currentType) continue;
                $scope.randomNum = Math.random();
                var random = ""; //= $scope.randomNum < 0.5 ? ("vo1_" + $scope.language) : ("vo2_" + $scope.language);
                switch (true){
                    case ($scope.randomNum >= 0 && $scope.randomNum < 0.25):
                        random = ("anxious_text_" + $scope.language);
                        break;
                    case ($scope.randomNum >= 0.25 && $scope.randomNum < 0.5):
                        random = ("agitated_text_" + $scope.language);
                        break;
                    case ($scope.randomNum >= 0.5 && $scope.randomNum < 0.75):
                        random = ("happy_text_" + $scope.language);
                        break;
                    case ($scope.randomNum >= 0.75 && $scope.randomNum < 1):
                        random = ("sad_text_" + $scope.language);
                        break;
                }
                
                return item[random];
            }
            return null;
        }

        function fetchReactionVO2() {
            for(var i = 0, count = $scope.items.length; i < count; i++) {
                var item = $scope.items[i];
                $scope.item = item;
                if(item.type !== $scope.currentType) continue;
                var random = ""; 
                switch (true){
                    case ($scope.randomNum >= 0 && $scope.randomNum < 0.25):
                        if(!$scope.done){
                            random = ("anxious_VO_" + $scope.language);
                        } else{
                            random = ("anxious_VO_complete_" + $scope.language); 
                        }
                        break;
                    case ($scope.randomNum >= 0.25 && $scope.randomNum < 0.5):
                        if(!$scope.done){
                            random = ("agitated_VO_" + $scope.language);
                        } else {
                            random = ("agitated_VO_complete_" + $scope.language);
                        }
                        break;
                    case ($scope.randomNum >= 0.5 && $scope.randomNum < 0.75):
                        if(!$scope.done){
                            random = ("happy_VO_" + $scope.language);
                        } else{
                            random = ("happy_VO_complete_" + $scope.language);                            
                        }
                        break;
                    case ($scope.randomNum >= 0.75 && $scope.randomNum < 1):
                        if(!$scope.done){
                            random = ("sad_VO_" + $scope.language);
                        } else {
                            random = ("sad_VO_complete_" + $scope.language);
                        }
                        break;
                }
                return item[random];
            }
            return null;
        }

        function setDefaultEmotion() {
            defaultEmotion = emotions[Math.floor(Math.random() * 4)];
            $scope.currentReaction = defaultEmotion;
            $scope.items = ITEMS_RESKIN.map(mapReskinItems);
        }


        function mapReskinItems(e) {
            e.reaction = e[defaultEmotion + "_reaction"];
            e['text1_' + $scope.language] = e['text2_' + $scope.language] = e[defaultEmotion + "_text_" + $scope.language];
            e['vo1_' + $scope.language] = e['vo2_' + $scope.language] = e[defaultEmotion + "_VO_" + $scope.language];
            return e;
        }
      }
    };
  });