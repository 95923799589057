 (function () {'use strict';}());
angular.module("app").directive('quizMultipleChoice', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/quiz-questions/multiple-choice.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        $scope.letters = ["A","B","C","D","E","F","G","H","I"];

        $scope.getFeedback = function(quiz) {
          var questions = quiz.questions.filter(hasFeedback);
          // if(!questions.length) return null;

          var feedback = "";

          $scope.feedbackText = $scope.question.feedback['text_' + $scope.language];

          //Correct / Incorrect results
          if(!questions.length){
            feedback += ("<div class=\"audio-block\">");
            feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
            // feedback += ("<p class=\"p\">" + $scope.question.feedback['text_' + $scope.language] + "</p>");
            feedback += ("</div>");

            return feedback;
          }
          //Condition results
          else{
            for(var i = 0, count = questions.length; i < count; i++) {
              var question = questions[i];
              var feedbk = question.feedback.presented['text_' + $scope.language];
              $scope.feedbackText = feedbk;

              // feedback += ("<div class=\"audio-block\">");
              // feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
              // feedback += ("<p class=\"p\">" + question.feedback.presented['text_' + $scope.language] + "</p>");
              // feedback += ("</div>");

              feedback += ("<div class=\"audio-block\">");
              feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
              // feedback += ("<p class=\"p\">" feedbk | parseFeedback:true "</p>");
              // feedback += ("<p class=\"p\">" + {{ feedback|parseFeedback:false }} + "</p>");
              feedback += ("</div>");
            }
            return feedback;
            }
         };

        //  $scope.getFeedbackText = function(quiz) {
        //   var questions = quiz.questions.filter(hasFeedback);
        //   // if(!questions.length) return null;

        //   var feedbk = "";

        //   //Correct / Incorrect results
        //   if(!questions.length){
        //     feedbk += ("<div class=\"audio-block\">");
        //     feedbk += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
        //     feedbk += ("<p class=\"p\">" + $scope.question.feedback['text_' + $scope.language] + "</p>");
        //     feedbk += ("</div>");

        //     return feedbk;
        //   }
        //   //Condition results
        //   else{
        //     for(var i = 0, count = questions.length; i < count; i++) {
        //       var question = questions[i];
        //       var text = question.feedback.presented['text_' + $scope.language];
        //       $scope.feedback = text;

        //       feedbk += ("<p class=\"p\" ng-bind-html=\"feedback|parseFeedback:false\" ></p>");
            
        //     }
        //     return feedbk;
        //     }
        // };

        function hasFeedback(e) {
          if(e.feedback.presented) return true;
          return false;
        }
      }  
    };
  });