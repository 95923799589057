(function () {'use strict';}());
angular.module("app").directive('simulationView', function(AppService) {
    return {
      restrict: 'A',
      templateUrl: 'views/simulation.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;

        $scope.showOptions = false;
        $scope.$on("hideOptions",function(){
          $scope.setShowOptions(false);
        });

        $scope.readScenarioVO = false;

        $scope.getFeedback = function() {
          var selection = $scope.selectionText; 
          $scope.simulationResult = selection['result_' + $scope.language];
          $scope.simulationResultVO = selection['resultVO_' + $scope.language];

          var feedback = "";
          feedback += ("<div class=\"audio-block\">");
          feedback += ("<button class=\"audio-play\" type=\"button\" data-click-audio=\"" +  $scope.simulationResultVO + "\" data-auto-audio=\"" +  $scope.simulationResultVO + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
          feedback += ("<p class=\"p scenario\">" + $scope.simulationResult + "\<br><br>" + $scope.textLookup.scenario_try_again[$scope.language]+ "</p>");
          feedback += ("</div>");

          return feedback;
        };
      
        $scope.setShowOptions = function(value) {
          $scope.readScenarioVO = false;
        	$scope.showOptions = value;
          $scope.setShowFooter(!value);
          if(value === true) {
            animateMenu();
            //$scope.setShowingMenu(true);
          } else {
            //$scope.setShowingMenu(false);
          }
        };

        $scope.submit = function(selection) {
            $scope.selectionText = selection;
            $scope.setShowFooter(true);
            $scope.setScenarioResponse(selection);
            $scope.setShowOptions(false);
            delayUpdate(); // delay to play VO AFTER star sfx
        };

        $scope.done = function() {
          var simulations = AppService.simulations;
          var index = $scope.currentSimulationIndex;
//          if(index >= simulations.length - 1) return alert("There are no more simulations available for the prototype.");
          //$scope.setCurrentSimulation(simulations[index + 1]);
          $scope.setShowFooter(true);
          $scope.setRecommendedScenario(null);
          $scope.setCurrentSimulation(null);
          $scope.clearBanner();
          $scope.setCurrentPage($scope.PAGE_HOME);
          $scope.checkScenarioAlert();
        };

        $scope.renderAlert = function() {
          if ($scope.language == 'en')
          {
            $scope.setCurrentAlert("Complete more scenarios to unlock.");
          }
          else
          {
            $scope.setCurrentAlert("Completa más escenarios para desbloquear.");
          }
        };

        $scope.retry = function() {
          $scope.setCurrentSimulation($scope.currentSimulation);
        };

        $scope.cancel = function($event) {
        	$event.preventDefault();
        	$event.stopImmediatePropagation();
          $scope.setShowFooter(true);
        	$scope.setShowOptions(false);
        };

        $scope.cancelSelection = function($event) {
          $event.preventDefault();
          $event.stopImmediatePropagation();
          $scope.preselect(null);
          $scope.setShowFooter(false);
        };

        $scope.optionIsDisabled = function(option,sk) {
          if(!option || !option.skill || !option.skill.length) return false;
          sk = sk.replace(/\s+/gim,"-");

          for(var i = 0, count = option.skill.length; i < count; i++) {
            var skill = option.skill[i];
            skill = skill.replace(/\s+/gim,"-");
            if(skill !== sk) continue;
            var economy = $scope.economy[skill];
            if(option.cost[i] > economy) return true;
          }
          return false;
        };

        $scope.getEconomy = function(option,sk) {
          if(!option || !option.skill || !option.skill.length) return 0;
          for(var i = 0, count = option.skill.length; i < count; i++) {
            var skill = option.skill[i];
            if(skill !== sk) continue;
            skill = skill.replace(/\s+/gim,"-");
            return $scope.economy[skill];
          }
          
        };

        $scope.getTotalForOption = function(option) {
          return option.cost * 3;
        };

        $scope.getSkill = function(option) {
          if(!option || !option.skill || !option.skill.length) return null;

          var output = "";
          for(var i = 0, count = option.skill.length; i < count; i++) {
            var skill = option.skill[i];
            skill = skill.replace(/\s+/gim,"");
            output += (skill + " ");
          }
          return output;
        };

        $scope.showButtons = function(show,selectedOption) {
            var costs = selectedOption.cost;
            var skills = selectedOption.skill;
            var satisfied = true;

            for(var i = 0, count = costs.length; i < count; i++) {
              var cost = costs[i];
              var skill = skills[i].replace(/\s+/gim,"-");
              if($scope.economy[skill] < cost) satisfied = false;
            }

            if(show === true && satisfied === true) return true;
            else if(show === true && satisfied !== true) return false;
            else if(show === false && satisfied === true) return false;
            else return true;
        };

        $scope.getNum = function(num) {
          num = num;
          if(num.toString().length > 1) return num;
          else return "0"+num;
        };

        function animateMenu() {
          var $menu = $(".options-container");
          $menu.css("top",$menu.position().top).delay(0).animate({top:0});
        }

        function delayUpdate() {
          setTimeout(function() {$scope.updateState();},2000);
        }
      }  
    };
  });