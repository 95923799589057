(function () {'use strict';}());

angular.module('app')
  .controller('GameController', function ($scope,AppService) {
  	var trace = angular.trace;

  	$scope.GAME_PUFFERFISH = 1;
  	$scope.GAME_CAT = 2;
  	$scope.GAME_BUBBLEPOP = 3;
  	$scope.GAME_RELAX_1 = 4;
    $scope.GAME_BRAINSIM = 5;
    $scope.GAME_BRAINSIM_2 = 6;
    $scope.GAME_REALITY_SLIDER_1 = 7;
    $scope.GAME_REALITY_SLIDER_2 = 8;
    $scope.GAME_REALITY_SLIDER_3 = 9;
    $scope.GAME_REALITY_SLIDER_4 = 10;
    $scope.GAME_REALITY_SLIDER_5 = 11;
    $scope.GAME_REALITY_SLIDER_6 = 12;
    $scope.GAME_RELAX_2 = 13;
    $scope.GAME_RELAX_3 = 14;
    $scope.GAME_RELAX_4 = 15;

    $scope.currentGame = null;
    $scope.currentSkill = null; //"decision-making";
    $scope.currentPoints = null; //3;
    $scope.showFeedbackScreen = false; //true;
    $scope.showGameAdded = false;
    $scope.showSkillPoints = false;
    $scope.launchedFromMenu = false;

    $scope.$watch("requestedGame",onGameRequested);

    $scope.setCurrentGame = function(value,skill,points) {
      if(!value) $scope.lastGame = $scope.currentGame;
    	$scope.currentGame = value;
      $scope.lastGameObject = getGameObject($scope.lastGame);

      if(value) $scope.launchedFromMenu = true;

      if(!value && !skill) {
        $scope.setCurrentExitCallback(null);
        $scope.setCurrentPage($scope.PAGE_HOME);
        $scope.unsetRequestedGame();
      }
      else if(skill && points) {
        $scope.currentSkill = skill;
        $scope.currentPoints = points;
        $scope.showSkillPoints = true;
        $scope.showFeedbackScreen = true;
      }

      if(value) $scope.setCurrentExitCallback(exitCallback);
      $scope.launchedFromSpinner = false;

      $scope.updateState();

      if(value) {
        ga('send', 'gameview',getGameName(value));
      }
    };

    $scope.done = function() {
      
      $scope.launchedFromMenu = false;
      
      for(var i = 0, count = $scope.spinnerSource.length; i < count; i++) {
        var item = $scope.spinnerSource[i];
        if(item.type === "game" && item.value === $scope.lastGame) {
          item.complete = true;
          item.source.complete = true;
          $scope.setSaving(true);
          AppService.saveGameResult($scope.userInfo.id, item.value, true, $scope.economy).then(onSaveComplete);
          break;
        }
      }
    };


    $scope.getGameState = function(value) {
      if(!$scope.spinnerSource) return "hidden";
      var games = $scope.spinnerSource.filter(filterGames);
      for(var i = 0, count = games.length; i < count; i++) {
        var game = games[i];
        if(game.value !== value) continue;
        if(game.complete === true) return null;
      }

      return "hidden";
    };

    $scope.showGameAddedScreen = function() {
      $scope.showSkillPoints = false;

      if($scope.launchedFromMenu === true) {
        return $scope.done();
      }
      $scope.$emit("removeBackBtn");
      $scope.showGameAdded = true;
    };

    $scope.getResultScreen = function() {
      if(scope.showSkillPoints && $scope.showGameAdded)
      {
        return true;
      }
    };

    function onSaveComplete() {

      $scope.updateSpinnerSource();
      $scope.currentSkill = null;
      $scope.currentPoints = null;
      $scope.showFeedbackScreen = false;
      $scope.showGameAdded = false;
      $scope.currentGame = null;
      $scope.setCurrentPage($scope.PAGE_HOME);
      $scope.setCurrentExitCallback(null);
      $scope.unsetRequestedGame();
      $scope.setSaving(false);
    }

    $scope.retry = function($event) {
      if($event) $event.preventDefault();
      $scope.showSkillPoints = false;
      onGameRequested($scope.lastGame,true);
      $scope.updateState();
    };

    function filterGames(e) {
      if(e.type === "game") return true;
      return false;
    }

    function onGameRequested(game,override) {
      if(!game) return;

      $scope.currentSkill = null;
      $scope.currentPoints = null;
      $scope.showFeedbackScreen = false;
      $scope.currentGame = game;
      $scope.setCurrentExitCallback(exitCallback);

      if(override !== true) {
        $scope.launchedFromSpinner = true;
      }
    }

    function getGameObject(value) {
      if(!$scope.games) return;
      for(var i = 0, count = $scope.games.length; i < count; i++) {
        var game = $scope.games[i];
        if(game.id === value) return game;
      }
      return null;
    }

    function getGameName(value) {
        for(var prop in $scope) {
          if(!prop.match(/GAME_/gim)) continue;
          if($scope[prop] !== value) continue;
          return prop.toLowerCase().replace(/game_/gim,"");
        }
        return null;
    }

    function exitCallback() {
      $scope.lastGame = $scope.currentGame;
      $scope.currentSkill = null;
      $scope.currentPoints = null;
      $scope.showFeedbackScreen = false;
      $scope.currentGame = null;
      $scope.launchedFromMenu = false;
      $scope.showSkillPoints = false;

      $scope.$emit("closeLoops");

      $scope.unsetRequestedGame();

      if($scope.launchedFromSpinner) $scope.setCurrentPage($scope.PAGE_HOME);
      $scope.launchedFromSpinner = false;
      $scope.setCurrentExitCallback(null);
      $scope.updateState();
    }
});
