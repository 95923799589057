(function () {'use strict';}());
angular.module("app").directive('passwordRecoveryView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/password-recovery.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;

        $scope.error = false;
        $scope.info = null;

        $scope.PR_SCREEN_USERNAME = 1;
        $scope.PR_SCREEN_PASSWORD = 2;
        $scope.currentScreen = $scope.PR_SCREEN_USERNAME;

        $scope.fetchUserQuestion = function() {
          $scope.getSecurityQuestionForUser().then(onSecurityResponse);
        };
        
        $scope.getDownloadContent = function() {
        	save("my-project-here-info.txt","username: " + $scope.userInfo.username + "\npassword: " + $scope.userInfo.password);
        };

        $scope.cancel = function($event) {
          $event.preventDefault();
          $scope.error = false;
          $scope.info = null;
          $scope.userInfo.recoveryUsername = null;
          $scope.userInfo.recoveryAnswer = null;
          $scope.userInfo.recoveryPassword = null;
          $scope.userInfo.recoveryConfirm = null;
          $scope.currentScreen = $scope.PR_SCREEN_USERNAME;
          $scope.setCurrentPage($scope.PAGE_ACCOUNT);
        };

        $scope.submitUserResponse = function() {
          var reference = $scope.info.answer ? $scope.info.answer.toString().toLowerCase().replace(/\s+/gim) : null;
          var response = $scope.userInfo.recoveryAnswer.toString().toLowerCase().replace(/\s+/gim);

          if(!reference || reference !== response) {
            $scope.error = "Answer incorrect. Try again.";
          } else {
            $scope.error = null;
            $scope.resetUserPassword($scope.info.userId, $scope.userInfo.recoveryPassword);
          }
        };

        $scope.recoverButtonDisabled = function() {
          if(!$scope.userInfo.recoveryPassword) return true;
          if($scope.userInfo.recoveryPassword !== $scope.userInfo.recoveryConfirm) return true;
          if(!$scope.userInfo.recoveryAnswer) return true;
          return false;
        };

        function onSecurityResponse(result) {
          result = result.data;
          
          if(result.error) {
            $scope.error = result.error;
            $scope.info = null;
          } else {
            $scope.error = false;
            $scope.info = result;
            $scope.currentScreen = $scope.PR_SCREEN_PASSWORD;
          }
        }
      }  
    };
  });