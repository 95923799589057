(function () {'use strict';}());
angular.module("app").directive('bubblepop', function($document,$timeout,$window) {
    return {
      restrict: 'A',
      templateUrl: 'views/stress-reliever/bubblepop.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        var timeout;
        var bubbles = [];

        $scope.BUBBLE_UNPOPPED = "unpopped";
        $scope.BUBBLE_POPPED = "popped";
        $scope.BUBBLE_POPPING = "popping";

        $scope.done = false;
        $scope.started = false;
        $scope.meterWidth = 0;
        $scope.rows = [];
        $scope.sound = "bubblepop1";

        $timeout(initialize,10);

        $scope.start = function() {
        	$scope.started = true;
            $scope.sound = $scope.getBubbleSound();
            $scope.updateState();
        };

    	$scope.onBubbleClick = function(bubble) {
            $scope.started = true;

            if(bubble.state !== $scope.BUBBLE_UNPOPPED) return;

            bubble.state = $scope.BUBBLE_POPPING;
            bubble.timer = $timeout(popBubble.bind(bubble),500);
            $scope.meterWidth = Math.min($scope.meterWidth + 5,100);
            $scope.sound = $scope.getBubbleSound();
            $scope.updateState();
        };

        $scope.complete = function() {
            $scope.economy.coping += 3;
        	$scope.setCurrentGame(null,"coping",3);
            $scope.autoPlayedBubbleDone = false;
            $scope.updateState();
        };

        $scope.regenerate = function() {
            bubbles.forEach(function(e){
                e.state = $scope.BUBBLE_UNPOPPED;
            });
            $scope.updateState();
        };

        $scope.getBubbleSound = function() {
            return "bubblepop" + [1,2,3,4][Math.floor(Math.random() * 4)];
        };

        function popBubble() {
        	var bubble = this;
            if(bubble.state !== $scope.BUBBLE_POPPING) return;
        	bubble.state = $scope.BUBBLE_POPPED;
        	//bubble.timer = $timeout(unpopBubble.bind(bubble),2500);
        }

        function unpopBubble() {
        	var bubble = this;
        	bubble.state = $scope.BUBBLE_UNPOPPED;
        }

        function initialize() {
        	renderBubbles();
         	angular.element($window).bind("resize",onResize);
            $scope.updateState();
        }

        function onResize() {
        	renderBubbles();
        	$scope.$digest();
        }

        function renderBubbles() {
            var divisor;
            $(".game.bubblepop .image-container").append("<div class='row offset bubblepoptest'></div>");
            divisor = parseInt($(".bubblepoptest").css("left")) * 2;
            $(".bubblepoptest").remove();
            trace(divisor);

        	$scope.rows = [];
            bubbles = [];

        	for(var i = 0; i < 4; i++) {
        		var row = {id: i, bubbles:[], offset: i % 2};
        		for(var j = 0, count = getBubbleCount(divisor); j < count; j++) {
        			if(row.offset === 1 && j === count - 1) break;
                    var bubble = {id:j,state: $scope.BUBBLE_UNPOPPED, timer: null};
        			row.bubbles.push(bubble);
                    bubbles.push(bubble);
        		}
        		$scope.rows.push(row);
        	}
        }

        function getBubbleCount(divisor) {
        	var width = $element.find(".image-container").outerWidth();
        	return Math.floor(width / divisor);
        }
      }  
    };
  });