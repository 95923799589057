(function () {'use strict';}());

angular.module('app')
  .controller('QuizController', function ($scope,$filter,AppService) {
  	var trace = angular.trace;

  	$scope.quiz = null;
    $scope.question = null;
    $scope.lastQuestion = false;
    $scope.showSkillPoints = false;
    $scope.launchedFromMenu = false;

  	$scope.$watch("currentActivity",onActivityChange);

    $scope.previous = function($event) {
      $scope.updateState();
      var question = getPreviousQuestion();
      var quizzes,quizIndex,quiz;

      $event.stopImmediatePropagation();
      $event.preventDefault();

      if($scope.feedback) {
        $scope.feedback = null;
        return;
      } else if(question) {
        $scope.next(question);
        return;
      } else if(question === false) {
        return;
      }

      //GTKY boundary change
      quizzes = $scope.quizzes.filter(filterTierZero);
      quizIndex = quizzes.indexOf($scope.quiz);
      if(quizIndex === 0) return;
      $scope.quiz = quizzes[quizIndex - 1];
      $scope.next($scope.quiz.questions[$scope.quiz.questions.length - 1]);
    };

    $scope.submit = function() {
      //if($scope.question.type === "Slider") $scope.question.selection = getSelectionForQuestion($scope.question);

      if($scope.question.selection && $scope.question.selection.correct) {
        $scope.economy[$scope.question.skill] += $scope.question.points;
      } else {
        $scope.economy[$scope.question.skill] += $scope.question.points;
      }

      $scope.setSaving(true);

      // Checklist quizzes remain in vault after reload
      if($scope.question.type == 'Checklist' && !$scope.question.selections){
        $scope.question.selections = [];
        for(var i = 0; i < $scope.question.options.length; i++)
        {
          $scope.question.selections.push($scope.question.options[i].id);
        }
      }
      AppService.saveQuizResponse($scope.userInfo.id, $scope.question.id,
        $scope.question.selection ? $scope.question.selection.id : null,
        mapSelections($scope.question.selections), $scope.economy).then(onSaveResponse);
    };

    $scope.selectQuiz = function(obj) {
      $scope.setCurrentActivity(obj);
      $scope.launchedFromMenu = true;
    };

    $scope.getButtonSound = function(checkCorrect) {
      if(!$scope.quiz) return null;


      var questions = $scope.quiz.questions;
      var isLastQuestion = questions.indexOf($scope.question) === questions.length - 1;
      var defaultSound = "button_alt";
      var tier = $scope.question.tier;
      var evaluateCorrectness = checkCorrect && $scope.question.selection && tier > 0;

      if(evaluateCorrectness && ($scope.question.selection.correct || $scope.question.correctOptionNum === 0)) defaultSound = "3star";
      else if(evaluateCorrectness) defaultSound = "1star";

      return isLastQuestion ? "quizresults" : defaultSound;
    };

    $scope.getButtonSoundCheckbox = function(option) {
      if(!$scope.quiz) return null;
      if($scope.question.selections && $scope.question.selections[option.id] === true) return "check";
      else if($scope.question.selections && $scope.question.selections[option.id] !== true) return "erase";
      return "check";
    };

    function onSaveResponse() {
      if($scope.question.feedback.type === "conditional") {
        $scope.feedback = getConditionalFeedback();

        $scope.feedbackVO = getConditionalFeedbackAudio();
        
        $scope.updateState();
      } else if($scope.question.feedback['text_' + $scope.language])  {
        $scope.feedback = $scope.question.feedback['text_' + $scope.language];
        $scope.feedbackVO = $scope.question.feedback['textVO_' + $scope.language];

        $scope.updateState();
      } else {
        $scope.next();
      }
      $scope.setSaving(false);
    }

    $scope.next = function(requested) {
      var index = $scope.quiz.questions.indexOf($scope.question);
      var tier = $scope.question.tier;

      if(requested && requested.id) {
        $scope.question = requested;
      } else if(index < $scope.quiz.questions.length - 1) {
        $scope.question = $scope.quiz.questions[index + 1];
      }
      else {
        $scope.question = null;
      }

      $scope.feedback = null;
      $scope.lastQuestion = $scope.question === $scope.quiz.questions[$scope.quiz.questions.length - 1];

      if(!$scope.question && tier !== 0) {
        for(var i = 0, count = $scope.spinnerSource.length; i < count; i++) {
          var item = $scope.spinnerSource[i];
          if(item.type === "quiz" && item.value === $scope.quiz.id) {
            item.complete = true;
            $scope.updateSpinnerSource();
            break;
          }
        }

        $scope.quiz.complete = true;

        var question = $scope.quiz.questions[$scope.quiz.questions.length - 1];

        $scope.showSkillPoints = true;
        $scope.currentPoints = question.points;
        $scope.currentSkill = question.skill;
      } else if(!$scope.question) {
        // console.log('quiz done');
        var quizzes = $scope.quizzes.filter(filterTierZero),next;
        index = quizzes.indexOf($scope.quiz);
        next = quizzes.length === index + 1 ? null : quizzes[index+1];
        $scope.setCurrentActivity(next ? {type:"quiz",value: next.id} : null);

        if(!next) {
          var reference = fetchQuiz(22);
          var feedback = reference.questions[reference.questions.length - 1].feedback.presented;
          var introvert = feedback.text_en.match(/^introvert/i);
          var extrovert = feedback.text_en.match(/^extrovert/i);

          if(introvert) {
            $scope.setRecommendedScenario(AppService.simulations[2]);
          } else if(extrovert) {
            $scope.setRecommendedScenario(AppService.simulations[0]);
          } else {
            $scope.setRecommendedScenario(AppService.simulations[1]);
          }

          $scope.setCurrentPage($scope.PAGE_OUTRO);
        }

        if(next !== null){
          if($scope.language == 'en')
          {
            if(!next.questions.textVO_en)
            {
              $scope.feedbackVO = next.questions[0].textVO_en;
            }
            else{
              $scope.feedbackVO = next.questions.textVO_en;
            }
          }
          else{
            if(!next.questions.textVO_es)
            {
              $scope.feedbackVO = next.questions[0].textVO_es;
            }
            else{
              $scope.feedbackVO = next.questions.textVO_es;
            }
          }
        }
      }

      else{
        $scope.feedbackVO = $scope.question['textVO_' + $scope.language];
      }

      $scope.updateState();
      window.scrollTo(0,0);
    };

    $scope.showQuizAddedScreen = function() {
        $scope.showSkillPoints = false;
        $scope.currentPoints = null;
        $scope.currentSkill = null;
        if($scope.launchedFromMenu === true) return $scope.returnToMenu();
        $scope.showQuizAdded = true;
        $scope.updateState();
    };

    $scope.returnToMenu = function() {
      $scope.showQuizAdded = false;
      $scope.launchedFromMenu = false;
      $scope.setCurrentActivity(null);
      $scope.setCurrentPage($scope.PAGE_HOME);
    };

    $scope.getQuizProgress = function(question) {
      if(question.tier !== 0) return (question.index + 1) + ' of ' + $scope.quiz.questions.length;
      var quizzes = $scope.quizzes.filter(filterTierZero),next;
      var questions = [], index;
      quizzes.forEach(function(q){ questions = questions.concat(q.questions); });
      index = questions.indexOf(question);
      return (index + 1) + ' of ' + questions.length;
    };

    $scope.getDoneLabel = function(question) {
      if(question.tier !== 0) return $scope.textLookup.done[$scope.language];
      var quizzes = $scope.quizzes.filter(filterTierZero),next;
      var questions = [], index;
      quizzes.forEach(function(q){ questions = questions.concat(q.questions); });
      index = questions.indexOf(question);
      if(index === questions.length - 1) return $scope.textLookup.done[$scope.language];
      return $scope.textLookup.continue[$scope.language];
    };

    $scope.backButtonIsDisabled = function() {
          var quizzes = $scope.quizzes.filter(filterTierZero);
          if(!$scope.quiz || !$scope.question) return true;
          if($scope.question.tier === 0 && quizzes.indexOf($scope.quiz) === 0 && $scope.quiz.questions.indexOf($scope.question) === 0) return true;
          else if($scope.question.tier !== 0 && $scope.quiz.questions.indexOf($scope.question) === 0 && !$scope.feedback) return true;
          return false;
        };

  	function onActivityChange(value) {
  		if(!value || value.type !== "quiz") return;
  		$scope.quiz = fetchQuiz(value.value);

      if($scope.quiz.tier === 0) {
        $scope.question = $scope.quiz.questions.filter(filterIncompleteQuestions)[0];
        if(!$scope.question) $scope.question = $scope.quiz.questions[0];
      } else {
        $scope.question = $scope.quiz.questions[0];
      }

      if($scope.question.tier === 0) {
        $scope.setCurrentExitCallback(null);
        return;
      }

      var allcomplete = $filter('scenarioscomplete')($scope.quizzes).length === $scope.quizzes.length;
      if(allcomplete === true) $scope.launchedFromMenu = true;

      if($scope.launchedFromMenu) {
        $scope.quiz.questions.forEach(function(e){
          e.selection = null;
          e.selections = null;
        });
      }

      $scope.setCurrentExitCallback(exitCallback);
      $scope.updateState();
  	}

    function getConditionalFeedback() {
      var conditions = $scope.question.feedback.conditions;
      var selections = $scope.question.selections;

      if($scope.question.type !== "Checklist" && !selections) return getConditionalFeedbackMC();

      var x = 0;

      for(var prop in selections) {
        if(selections[prop] === true) x++;
      }


      for(var i = 0, count = conditions.length; i < count; i++) {
        var condition = conditions[i];

        /* for now, we need to use eval to execute the conditional feedback for quiz items where the logic
        is defined in the import spreadsheet. new Function approach also fails jslint */

        /*jslint evil: true */
        var satisfied = eval(condition.rule);
        if(satisfied === true) {
          $scope.question.feedback.presented = condition;
          return condition['text_' + $scope.language];
        }
      }
      return null;
    }

    function getConditionalFeedbackAudio() {
      var conditions = $scope.question.feedback.conditions;
      var selections = $scope.question.selections;

      if($scope.question.type !== "Checklist" && !selections) return getConditionalFeedbackMCAudio();

      var x = 0;

      for(var prop in selections) {
        if(selections[prop] === true) x++;
      }

      for(var i = 0, count = conditions.length; i < count; i++) {
        var condition = conditions[i];

        /* for now, we need to use eval to execute the conditional feedback for quiz items where the logic
        is defined in the import spreadsheet. new Function approach also fails jslint */

        /*jslint evil: true */
        var satisfied = eval(condition.rule);
        if(satisfied === true) {
          $scope.question.feedback.presented = condition;
          return condition['textVO_' + $scope.language];
        }
      }
      return null;
    }

    function getConditionalFeedbackMC() {
      var conditions = $scope.question.feedback.conditions;
      var questions = $scope.quiz.questions;
      var x = 0,i,count;

      for(i = 0, count = questions.length; i < count; i++) {
        var question = questions[i];
        var selection = question.selection;
        x+= selection.value;
      }

      for(i = 0, count = conditions.length; i < count; i++) {
        var condition = conditions[i];
        /*jslint evil: true */
        var satisfied = eval(condition.rule);
        if(satisfied === true) {
          $scope.question.feedback.presented = condition;
          return condition['text_' + $scope.language];
        }
      }
      return null;
    }

    function getConditionalFeedbackMCAudio() {
      var conditions = $scope.question.feedback.conditions;
      var questions = $scope.quiz.questions;
      var x = 0,i,count;

      for(i = 0, count = questions.length; i < count; i++) {
        var question = questions[i];
        var selection = question.selection;
        x+= selection.value;
      }

      for(i = 0, count = conditions.length; i < count; i++) {
        var condition = conditions[i];
        /*jslint evil: true */
        var satisfied = eval(condition.rule);
        if(satisfied === true) {
          $scope.question.feedback.presented = condition;
          return condition['textVO_' + $scope.language];
        }
      }
      return null;
    }

  	function fetchQuiz(value) {
  		for(var i = 0, count = $scope.quizzes.length; i < count; i++) {
  			var quiz = $scope.quizzes[i];
  			if(quiz.id === value) return quiz;
  		}
  		return null;
  	}

    function exitCallback() {
      $scope.quiz = null;
      $scope.question = null;
      $scope.showSkillPoints = false;
      $scope.showQuizAdded = false;
      $scope.currentPoints = $scope.currentSkill = null;
      $scope.feedback = null;
      $scope.setCurrentActivity(null);
      $scope.setCurrentPage($scope.PAGE_HOME);
      $scope.setCurrentExitCallback(null);
      $scope.launchedFromMenu = false;
    }

    function filterTierZero(e) {
      var questions = e.questions;
      if(!questions || !questions.length) return false;
      var question = questions[0];
      if(question.tier > 0) return false;
      return true;
    }

    function getSelectionForQuestion(q) {
      var s = parseInt(q.selection.id);
      for(var i = 0, count = q.options.length; i < count; i++) {
        var option = q.options[i];
        if(option.id === s) return option;
      }
      return null;
    }

    function mapSelections(obj) {
        if(!obj) return null;
        var output = [];
        for(var prop in obj) {
          if(obj[prop] === false) continue;
          output.push(parseInt(prop));
        }
        return output;
    }

    function filterIncompleteQuestions(e) {
      return e.selection ? false : true;
    }

    function getPreviousQuestion() {
      var index = $scope.quiz.questions.indexOf($scope.question);
      var quizzes, quizIndex;

      if($scope.question.tier === 0 && index === 0) {
        //this is not going to work, we will need to update the current quiz defined on scope too if we're going across boundaries
        return null;
      } else if(index === 0) {
        return false;
      }
      $scope.updateState();
      return $scope.quiz.questions[index - 1];
    }
});
