angular.module('app').filter('scenarios', function() {
    return function(listing,gtky,reverse) {
    	if(!listing) return listing;
        var trace = angular.trace;
        var results = [], completed, numCompleted;

        if(!listing) return null;

        var tier1 = listing.filter(filterByTier,{tier:1});
        var tier2 = listing.filter(filterByTier,{tier:2});
        var tier3 = listing.filter(filterByTier,{tier:3});
        var tier4 = listing.filter(filterByTier,{tier:4});
        var tier5 = listing.filter(filterByTier,{tier:5});
        var tier6 = listing.filter(filterByTier,{tier:6});

        var tier1Completed = tier1.filter(filterCompleted);
        var tier2Completed = tier2.filter(filterCompleted);
        var tier3Completed = tier3.filter(filterCompleted);
        var tier4Completed = tier4.filter(filterCompleted);
        var tier5Completed = tier5.filter(filterCompleted);
        var tier6Completed = tier6.filter(filterCompleted);

        results = tier1;

        if(tier1Completed.length >= 2) results = results.concat(tier2);
        if(tier2Completed.length >= 2) results = results.concat(tier3);
        if(tier3Completed.length >= 2) results = results.concat(tier4);
        if(tier4Completed.length >= 2) results = results.concat(tier5);
        if(tier5Completed.length >= 2) results = results.concat(tier6);


        if(reverse === true) results.reverse();


    	return results;

    	function filterCompleted(e) {
    		return e.complete === true;
    	}

    	function filterByTier(e) {
    		return e.tier === this.tier;
    	}
    };
});