angular.module('app').filter('parseFeedback', function() {
    return function(text,headerOnly) {
    	if(!text) return text;
        var trace = angular.trace;
        var regExp = /^(.*?):\s+?(.*?)$/gim;
        var result = regExp.exec(text);

        if(!result) return text;
        if(headerOnly) return result[1];
        return result[2];
    };
});