(function () {'use strict';}());
angular.module("app").directive('settingsView', function($document,$timeout,$filter) {
    return {
      restrict: 'A',
      templateUrl: 'views/settings.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        
        $scope.print = function() {
        	window.print();
        };

        $scope.getCompleteQuizzes = function() {

          var count = 0;

          $scope.quizzes.forEach(function(e){
            if(e.tier === 0) return;
            if(e.complete === true) count++;
          });
          return count;
        };
      }  
    };
  });