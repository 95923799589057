(function () {'use strict';}());
angular.module("app").directive('quizChecklist', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/quiz-questions/checklist.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;

        $scope.isDisabled = function(selections) {
          return false;
        	/*for(var prop in selections) {
        		if(selections[prop]) return false;
        	}

        	return true;*/
        };

        $scope.getFeedback = function(quiz) {
          var questions = quiz.questions.filter(hasFeedback);

          if ($scope.question.feedback.presented.id == 7 | $scope.question.feedback.presented.id == 22 | $scope.question.feedback.presented.id == 37){
            $scope.chillSkills = true;
          }
          else {
            $scope.chillSkills = false;
          }

          var feedback = "";
          $scope.feedbackText = $scope.question.feedback['text_' + $scope.language];

          //Correct / Incorrect results
          if(!questions.length){
            feedback += ("<div class=\"audio-block\">");
            feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
            feedback += ("</div>");

            return feedback;
          }
          //Condition results
          else{
            for(var i = 0, count = questions.length; i < count; i++) {
              var question = questions[i];

              $scope.feedbackText = question.feedback.presented['text_' + $scope.language];

              feedback += ("<div class=\"audio-block\">");
              feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
              feedback += ("</div>");
            }
            return feedback;
            }
         };

         function hasFeedback(e) {
          if(e.feedback.presented) return true;
          return false;
        }

    }
	};
});