(function () {'use strict';}());
angular.module("app").directive('realitySlider', function($document,AppService) {
    return {
      restrict: 'A',
      templateUrl: 'views/reality-slider/reality-slider.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        var source = AppService.slider[parseInt(attr.realitySlider)-1];

        $scope.done = false;
        $scope.started = false;
        $scope.beforeImage = { en: source.beforeImage_en, es: source.beforeImage_es };
        $scope.afterImage = { en: source.afterImage_en, es: source.afterImage_es };
        $scope.beforeImageAlt = { en: source.beforeImageAlt_en, es: source.beforeImageAlt_es };
        $scope.afterImageAlt = { en: source.afterImageAlt_en, es: source.afterImageAlt_es };
        $scope.beforeText = { en: source.beforeText_en, es: source.beforeText_es };
        $scope.afterText = { en: source.afterText_en, es: source.afterText_es };
        $scope.beforeTextVO = { en: source.beforeTextVO_en, es: source.beforeTextVO_es };
        $scope.afterTextVO = { en: source.afterTextVO_en, es: source.afterTextVO_es };

        $scope.currentViewLabel = { en: "Myth", es: "Mito" };
        $scope.afterViewLabel = { en: "Reality", es: "Realidad" };

        $scope.currentSliderInfo = {value: 0};

        $scope.complete = function() {
          $scope.economy["myth-busting"] += 2;
        	$scope.setCurrentGame(null,"myth-busting",2);
        };
      }
    };
  });