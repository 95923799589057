(function () {'use strict';}());
angular.module("app").directive('spinner', function($document,$timeout, $interval, AppService) {
    return {
      restrict: 'A',
      templateUrl: 'views/spinner.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        var INCREMENT = 50;
        var source = [];
        var available = [];
        var defaultUnspun = {src_en:"images/home/spinner_intro_en.png",src_es:"images/home/spinner_intro_es.png",alt_en:"select spin to start", alt_es:"select spin to start", first: true, isDefault: true};
        var defaultSpun = {src_en:"images/home/spinner_spin.png",src_es:"images/home/spinner_spin_es.png",alt_en:"spin", alt_es:"spin", isDefault: true};
        var sourceIndex = 0;
        var timeSpinning = 0;
        var promise = null;
        var spinning = false;
        var totalSpins = 0;

        $scope.currentItem = null;
        $scope.currentItemStyle = {"background-image": null, "background-repeat": "no-repeat", "background-size": "contain"};
        $scope.currentItemStyle_en = {"background-image": null, "background-repeat": "no-repeat", "background-size": "contain"};
        $scope.currentItemStyle_es = {"background-image": null, "background-repeat": "no-repeat", "background-size": "contain"};
        $scope.$watch("spinnerSource",onSpinnerSourceSet);

        $scope.spin = function() {
          if(spinning === true) return;

          available = filterAvailable();
          // console.log('available', available);
          spinIndex = 0;
          timeSpinning = 0;
          spinning = true;
          if(promise) $interval.cancel(promise);
          promise = $interval(spin,INCREMENT);
          totalSpins++;
        };

        $scope.selectCurrentItem = function() {
          if($scope.currentItem.isDefault || $scope.currentItem.type === "funfact") return;
          if(spinning === true) return;

          $scope.setCurrentActivity($scope.currentItem);
          $scope.currentItem.spinnerSelected = true;
          $scope.currentItem.complete = true;

          $scope.currentItem = defaultSpun;
          $scope.currentItemStyle["background-image"] = "url("+$scope.currentItem['src_' + $scope.language] + ")";
          $scope.currentItemStyle_en["background-image"] = "url("+$scope.currentItem.src_en + ")";
          $scope.currentItemStyle_es["background-image"] = "url("+$scope.currentItem.src_es + ")";

          $scope.updateState();

        };

        $scope.showPlayButton = function() {
          if(!$scope.currentItem || $scope.currentItem.isDefault || spinning || $scope.currentItem.type === "funfact") return true;
          return false;
        };

        function spin() {
          spinIndex ++;
          timeSpinning += INCREMENT;
          if(spinIndex >= available.length) spinIndex = 0;
          $scope.currentItem = available[spinIndex];
          $scope.currentItemStyle["background-image"] = "url("+$scope.currentItem['src_' + $scope.language] + ")";
          $scope.currentItemStyle_en["background-image"] = "url("+$scope.currentItem.src_en + ")";
          $scope.currentItemStyle_es["background-image"] = "url("+$scope.currentItem.src_es + ")";

          if($scope.currentPage == $scope.PAGE_HOME)
          {
            if(timeSpinning >= 4200) {
              $interval.cancel(promise);
              promise = null;
              spinning = false;

              // autoplay spinner VO
              if($scope.currentAutoVO.volume == 1)
              {
                $scope.playAudio($scope.currentItem['vo_' + $scope.language]);
                if($scope.currentItem.type === "funfact") {
                  $scope.currentItem.complete = true;
                  $scope.playAudio($scope.currentItem['vo_' + $scope.language]);
                  AppService.saveFunFact($scope.userInfo.id, $scope.currentItem.id);
                }
              }
            }
          }
          
        }

        function onSpinnerSourceSet(loop) {
          if(!loop) return;
          source = loop.concat();

          $scope.currentItem = defaultUnspun;
          $scope.currentItemStyle["background-image"] = "url("+$scope.currentItem['src_' + $scope.language] + ")";
          $scope.currentItemStyle_en["background-image"] = "url("+$scope.currentItem.src_en + ")";
          $scope.currentItemStyle_es["background-image"] = "url("+$scope.currentItem.src_es + ")";
        }

        function filterAvailable() {
          var tier1 = source.filter(filterTier,{tier:1});
          var tier2 = source.filter(filterTier,{tier:2});
          var tier3 = source.filter(filterTier,{tier:3});
          var tier4 = source.filter(filterTier,{tier:4});

          var tier1Complete = tier1.filter(filterTierComplete);
          var tier2Complete = tier2.filter(filterTierComplete);
          var tier3Complete = tier3.filter(filterTierComplete);
          var tier4Complete = tier4.filter(filterTierComplete);

          var tier1Incomplete = tier1.filter(filterTierIncomplete);
          var tier2Incomplete = tier2.filter(filterTierIncomplete);
          var tier3Incomplete = tier3.filter(filterTierIncomplete);
          var tier4Incomplete = tier4.filter(filterTierIncomplete);

          var output = tier1Incomplete;

          if(tier1Complete.filter(filterFunfacts).length >= 5) {
            output = output.concat(tier2Incomplete);
          }

          if(tier2Complete.filter(filterFunfacts).length >= 8) {
            output = output.concat(tier3Incomplete);
          }

          if(tier3Complete.filter(filterFunfacts).length >= 8) {
            output = output.concat(tier4Incomplete);
          }

          if(tier1Complete.length === 0 || totalSpins % 2 === 0) {
            output = output.filter(filterFunfacts);
          } else {
            // output = output.filter(filterFunfacts);
            output = output.filter(filterPersonalityFunFacts,{optimistQuiz:$scope.fetchQuiz(21), extrovertQuiz:$scope.fetchQuiz(22), typeAQuiz: $scope.fetchQuiz(23)});
          }

          trace(output);

          if(!output.length || output.length == 1) output = source;
          output.forEach(function(e){e.random = Math.random();});
          output.sort(sortRandom);
          return output;
        }

        function filterTier(e) {
          if(e.tier === this.tier) return true;
          return false;
        }

        function filterTierComplete(e) {
          if(e.complete === true) return true;
          return false;
        }

        function filterTierIncomplete(e) {
          if(e.complete === true) return false;
          return true;
        }

        function filterPersonalityFunFacts(e) {
          var optimistQuiz = this.optimistQuiz;
          var extrovertQuiz = this.extrovertQuiz;
          var typeAQuiz = this.typeAQuiz;
          var feedback, introvert, extrovert, ambivert, optimist, pessimist, realist, typea, typeb;

          if(e.type !== "funfact") return true;
          if(e.available === "all" && !e.complete) return true;
          if(e.complete) return false;

          if(!$scope.skippedQuiz)
          {
            if(extrovertQuiz.questions[extrovertQuiz.questions.length - 1].feedback) {
              feedback = extrovertQuiz.questions[extrovertQuiz.questions.length - 1].feedback.presented.text_en;
              introvert = feedback ? feedback.match(/^introvert/i) : false;
              extrovert = feedback ? feedback.match(/^extrovert/i) : false;
              ambivert = feedback ? feedback.match(/^ambivert/i) : false;

              if(e.available === "introvert" && introvert) return true;
              if(e.available === "extrovert" && extrovert) return true;
              if(e.available === "ambivert" && ambivert) return true;
            }

            if(optimistQuiz.questions[optimistQuiz.questions.length - 1].feedback) {
              feedback = optimistQuiz.questions[optimistQuiz.questions.length - 1].feedback.presented.text_en;
              optimist = feedback ? feedback.match(/^optimist/i) : false;
              pessimist = feedback ? feedback.match(/^pessimist/i) : false;
              realist = feedback ? feedback.match(/^realist/i) : false;

              if(e.available === "optimist" && optimist) return true;
              if(e.available === "pessimist" && pessimist) return true;
              if(e.available === "realist" && realist) return true;
            }

            if(typeAQuiz.questions[typeAQuiz.questions.length - 1].feedback) {
              feedback = typeAQuiz.questions[typeAQuiz.questions.length - 1].feedback.presented.text_en;
              typea = feedback ? feedback.match(/type a/i) : false;
              typeb = feedback ? feedback.match(/type b/i) : false;

              if(e.available === "typea" && typea) return true;
              if(e.available === "typeb" && typeb) return true;
            }
          }
          else {
            return true;
          }

        }

        function sortRandom(a,b) {
          if(a.random > b.random) return 1;
          if(a.random < b.random) return -1;
          return 0;
        }

        function filterFunfacts(e) {
          if(e.type === "funfact") return false;
          return true;
        }
      }
    };
  });