(function () {'use strict';}());
angular.module("app").directive('quizMultipleChoiceIcons', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/quiz-questions/multiple-choice-icons.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        $scope.letters = ["A","B","C","D","E","F","G","H","I"];
        
        $scope.getOptionImage = function(option,selection) {
          if(!option) return null;
          if(!selection) return option.image;


          var regex, result, path, ext;
          var image = option.image;
          var hl = option === selection ? "_highlight" : "";
          regex = /^(.*?)\.(.*?)$/gim;
          result = regex.exec(image);
          path = result[1];
          ext = result[2];

          return path + hl + "." + ext;
          //{{option.image}}
        };

        $scope.getFeedback = function(quiz) {
          var questions = quiz.questions.filter(hasFeedback);
          // if(!questions.length) return null;

          var feedback = "";

          $scope.feedbackText = $scope.question.feedback['text_' + $scope.language];

          //Correct / Incorrect results
          if(!questions.length){
            feedback += ("<div class=\"audio-block\">");
            feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
            // feedback += ("<p class=\"p\">" + $scope.question.feedback['text_' + $scope.language] + "</p>");
            feedback += ("</div>");

            return feedback;
          }
          //Condition results
          else{
            for(var i = 0, count = questions.length; i < count; i++) {
              var question = questions[i];
              $scope.feedbackText = question.feedback.presented['text_' + $scope.language];

              feedback += ("<div class=\"audio-block\">");
              feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
              // feedback += ("<p class=\"p\">" + question.feedback.presented['text_' + $scope.language] + "</p>");
              feedback += ("</div>");
            }
            return feedback;
            }
         };

         function hasFeedback(e) {
          if(e.feedback.presented) return true;
          return false;
        }
      }  
    };
  });