angular.module('app').filter('scenarioscomplete', function() {
    return function(listing,gtky) {
    	if(!listing) return listing;
        var trace = angular.trace;
        var results = [];

        if(!listing) return null;

        return listing.filter(filterCompleted);

    	function filterCompleted(e) {
    		return e.complete === true;
    	}
    };
});