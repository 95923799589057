(function () {'use strict';}());
angular.module("app").directive('quizSlider', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/quiz-questions/slider.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        $scope.currentRangeInfo = {value:2};

        if($scope.question.selection) {
        	var options = $scope.question.options;
        	var index = options.indexOf($scope.question.selection) + 1;
        	$scope.currentRangeInfo = {value:index};
        } else {
          $scope.question.selection = $scope.question.options[1];
        }

        $scope.$watch("question",onQuestionChange);


        $scope.onSliderSelect = function() {
        	$scope.question.selection = $scope.question.options[$scope.currentRangeInfo.value - 1];
        };

        function onQuestionChange(value) {
          if(!value) return;
          if($scope.question.selection) {
            var options = $scope.question.options;
            var index = options.indexOf($scope.question.selection) + 1;
            $scope.currentRangeInfo = {value:index};
          } else {
            $scope.currentRangeInfo = {value:2};
            $scope.question.selection = $scope.question.options[1];
          }

        }

        $scope.getFeedback = function(quiz) {
          var questions = quiz.questions.filter(hasFeedback);
          // if(!questions.length) return null;

          var feedback = "";

          //Correct / Incorrect results
          if(!questions.length){
            feedback += ("<div class=\"audio-block\">");
            feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  $scope.question.feedback['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
            feedback += ("<p class=\"p\" style =\"max-width: 32.5rem;\">" + $scope.question.feedback['text_' + $scope.language] + "</p>");
            feedback += ("</div>");

            return feedback;
          }
          //Condition results
          else{
            for(var i = 0, count = questions.length; i < count; i++) {
              var question = questions[i];

              feedback += ("<div class=\"audio-block\">");
              feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\" data-auto-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
              feedback += ("<p class=\"p\" style =\"max-width: 32.5rem;\">" + question.feedback.presented['text_' + $scope.language] + "</p>");
              feedback += ("</div>");
            }
            return feedback;
            }
         };

         function hasFeedback(e) {
          if(e.feedback.presented) return true;
          return false;
        }
        
      }  
    };
  });