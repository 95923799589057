(function () {'use strict';}());
angular.module("app").directive('welcomeView2', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/welcome-2.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        
      }  
    };
  });