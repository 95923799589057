(function () {'use strict';}());
angular.module("app").directive('quizView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/quiz.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;

        $scope.allQuizzesComplete = function() {
        	var quizzes = $scope.quizzes;
        	for(var i = 0, count = quizzes.length; i < count; i++) {
        		var quiz = quizzes[i];
        		if(!quiz.complete) return false;
        	}
        	return true;
        };
      }  
    };
  });