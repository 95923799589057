(function () {'use strict';}());

angular.module('app')
  .controller('SimulationController', function ($scope,AppService) {
  	var trace = angular.trace;

  	$scope.currentSimulation = null;
    $scope.currentBanner = null;
    $scope.currentBannerAlt = null;
    $scope.currentScenarioText = null;
    $scope.currentTrophy = null;
    $scope.currentResponseId = 0;
    $scope.currentResponseScore = null;
    $scope.showResult = false;
    $scope.currentSimulationIndex = 0;
    $scope.selectedOption = null;
    $scope.simulations = null;
    $scope.currentResponseObject = null;
    $scope.alert = null;

    $scope.$watch("ready",initialize);
    $scope.$watch("recommendedScenario",onReccomendationSet);
    $scope.$on("resetRecommendedScenario",function(e,value){
      onReccomendationSet(value);
    });

    $scope.setScenarioResponse = function(object) {
      var incomplete = $scope.simulations.filter(filterIncomplete);
      var imperfect = $scope.simulations.filter(filterImperfect);

      $scope.currentScenarioText_en = object.result_en;
      $scope.currentScenarioText_es = object.result_es;
      $scope.currentBanner = object.banner;
      $scope.currentBannerAlt_en = object.bannerAlt_en;
      $scope.currentBannerAlt_es = object.bannerAlt_es;
      $scope.currentTrophy = object.trophy;
      $scope.currentResponseId = object.id;
      $scope.currentResponseScore = object.score;
      $scope.showResult = true;
      $scope.selectedOption = null;
      $scope.currentSimulation.score = object.score;
      $scope.currentResponseObject = object;

      if(object && object.skill) {
        var skills = object.skill;
        for(var i = 0, count = skills.length; i < count; i++) {
          var skill = skills[i];
          skill = skill.replace(/\s+/gim,"-");
          $scope.economy[skill] -= object.cost[i];
        }
      }

      $scope.playAudio("spendpoints_"+object.score+"star");

      if(object) {
        $scope.currentSimulation.complete = true;
        checkAllComplete(incomplete.length, imperfect.length);
        $scope.setSaving(true);
        AppService.saveSimulationResponse($scope.userInfo.id, $scope.currentSimulation.id,object.id,$scope.economy).then(onSaveResponse);
      } else if(incomplete.length) {
        $scope.currentSimulation.complete = true;
        checkAllComplete(incomplete.length, imperfect.length);
      }
    };

    function onSaveResponse() {
      $scope.setSaving(false);
    }

    $scope.setCurrentSimulation = function(object) {
      $scope.readScenarioVO = true;
      $scope.$emit("setAutoPlayedSim");
      $scope.currentSimulation = object;
      $scope.currentTrophy = null;
      $scope.showResult = false;
      $scope.currentResponseId = 0;
      $scope.currentResponseScore = null;
      $scope.currentResponseObject = null;
      $scope.selectedOption = null;

      if(object) {
        $scope.currentBanner = $scope.currentSimulation.banner;
        $scope.currentBannerAlt = $scope.currentSimulation.bannerAlt;
        $scope.currentScenarioText = $scope.currentSimulation.scenario;
        $scope.currentSimulation.selection = null;
        $scope.currentSimulationIndex = AppService.simulations.indexOf($scope.currentSimulation);
        $scope.setCurrentExitCallback(exitCallback);
      } else {
        $scope.currentBanner = null;
        $scope.currentBannerAlt = null;
        $scope.currentScenarioText = null;
        $scope.currentSimulationIndex = null;
        $scope.setCurrentExitCallback(null);
      }

      $scope.updateState();

    };

    $scope.preselect = function(option) {
      $scope.selectedOption = option;
      $scope.updateState();
    };

    $scope.select = function() {
      $scope.setScenarioResponse($scope.selectedOption);
      $scope.selectedOption = true;
      $scope.updateState();

    };

    $scope.getCurrentResponseIndex = function(currentResponseId) {
      if(!$scope.currentResponseObject) return 0;
      return $scope.currentSimulation.options.indexOf($scope.currentResponseObject) + 1;
    };

    $scope.clearBanner = function() {
      $scope.currentBanner = null;
    };

  	function initialize(ready) {
      if(!ready) return;
      $scope.simulations = AppService.simulations;
  	}

    function onReccomendationSet(value) {
      $scope.setCurrentSimulation(value);
    }

    function exitCallback() {
      $scope.showResult = false;
      $scope.currentBanner = null;
      $scope.currentSimulation = null;
      $scope.currentTrophy = null;
      $scope.showResult = false;
      $scope.currentResponseId = 0;
      $scope.currentResponseScore = null;
      $scope.currentResponseObject = null;
      $scope.selectedOption = null;
      $scope.$broadcast("hideOptions");
      $scope.setCurrentSimulation(null);
    }

    function checkAllComplete(previouslyIncomplete,previouslyImperfect) {
      var incomplete = $scope.simulations.filter(filterIncomplete);
      var imperfect = $scope.simulations.filter(filterImperfect);

      var alertTextDone = "";
      var alertTextContinue = "";

      if($scope.language == 'en')
      {
        alertText = "Congratulations! You got three stars on every scenario! Now you’re ready to make healthy choices in the future.";
        alertTextContinue = "Great work! You’ve completed all the scenarios. Keep playing to go for three stars on every scenario!";
      }
      else{
        alertText = "¡Felicidades! ¡Tienes tres estrellas en cada escenario! Ahora estás listo/a para tomar decisiones saludables en el futuro."; 
        alertTextContinue = "¡Excelente trabajo! Has completado todos los escenarios. ¡Sigue jugando para conseguir tres estrellas en cada escenario!"; 
      }

      if(imperfect.length === 0 && previouslyIncomplete === 0 && previouslyImperfect === 1) $scope.alert = alertText;
      if(incomplete.length || previouslyIncomplete !== 1) return false;

      $scope.alert = imperfect.length ? alertTextContinue : alertText;
    }

    // display alert on return to home page
    $scope.checkScenarioAlert = function()
    {
      var alert = $scope.alert;
      $scope.alert = null;
      return $scope.setCurrentAlert(alert);
    };

    function filterImperfect(e) {
      return e.score !== 3;
    }

    function filterIncomplete(e) {
      return e.complete !== true;
    }


  });
