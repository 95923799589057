(function () {'use strict';}());
angular.module("app").directive('accountView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/account.html',

      link:function($scope,$element,attr)
      {
        // got from
        // const _self = this;
        // window['onSignIn'] = function (user) {
        //   console.log('sogg in');
        //   _self.onSuccess(user);
        // };
        var trace = angular.trace;
        $scope.ST_LOGIN = "login";
        $scope.ST_BIRTHDATE = "birthdate";
        $scope.ST_CREATE_12 = "create12";
        $scope.ST_CREATE_13 = "create13";

        // console.log($scope.textLookup);

        $scope.currentScreenType = $scope.ST_LOGIN;

        $scope.setCurrentScreenType = function(type,$event) {
        	$scope.currentScreenType = type;
          $scope.currentResponse = null;

        	if($event) {
        		$event.preventDefault();
        		$event.stopImmediatePropagation();
        	}

        	//if(type === $scope.ST_BIRTHDATE) $timeout(configureDatePicker,0);
        };

        $scope.setBirthdate = function() {
        	var date = new Date($scope.userInfo.birthdate);
        	var age = calculateAge(date);
        	if(age < 13) $scope.setCurrentScreenType($scope.ST_CREATE_12);
        	else $scope.setCurrentScreenType($scope.ST_CREATE_13);
        };

        $scope.accountButtonDisabled = function() {
          if(!$scope.userInfo.password || !$scope.userInfo.confirm) return true;
          if($scope.userInfo.password !== $scope.userInfo.confirm) return true;
          return false;
         };

         $scope.createAccount = function() {
          $scope.currentResponse = null;
          $scope.register().then(onRegister);
         };
         $scope.signin = function() {
          $scope.currentResponse = null;
          $scope.login().then(onLogin);
         };

         function onRegister(response) {
          if(response.data.authenticated === true) return;
          $scope.currentResponse = response.data;
         }

         function onLogin(response) {
          if(response.data.authenticated === true) return;
          $scope.currentResponse = response.data;
         }

        function configureDatePicker() {
          $element.find("#birthdate").datepicker({changeYear:true,
    											yearRange: "1950:2018",
    											defaultDate: new Date("2010/01/01")
    										}).on("change",update);
        }

        function update(date) {
          var value = $(this).val();
          $scope.$apply(function(){
            $scope.userInfo.birthdate = formatDate(value);
            $scope.userInfo.birthdateFormatted = value;
          });
        }


        function formatDate(value) {
          var exp = /^(\d{2})\/(\d{2})\/(\d{4})$/gim;
          var result = exp.exec(value);
          return result[3] + "-" + result[1] + "-" + result[2];
        }

        function calculateAge(birthday) { // birthday is a date
		    var ageDifMs = Date.now() - birthday.getTime();
		    var ageDate = new Date(ageDifMs); // miliseconds from epoch
		    return Math.abs(ageDate.getUTCFullYear() - 1970);
		}

      }
    };
  });