(function () {'use strict';}());

angular.module('app')
  .controller('HomeController', function ($scope,$rootScope,AppService) {
  	var trace = angular.trace;

    $scope.currentItem = null;

    $scope.setCurrentItem = function(item) {
      $scope.currentItem = item;
    };

    $scope.selectCurrentItem = function() {
      $scope.setCurrentActivity($scope.currentItem);
    };

    $scope.startSimulations = function() {
      if($scope.recommendedScenario) {
        $rootScope.$broadcast("resetRecommendedScenario",$scope.recommendedScenario);
        $scope.setCurrentPage($scope.PAGE_SIMULATION_INTRO);
      }
      else $scope.setCurrentPage($scope.PAGE_SIMULATION);
    };

});
