(function () {'use strict';}());
angular.module("app").directive('cat', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/stress-reliever/cat.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        var timeout;

        $scope.CAT_MODE_ANIM = "gif";
        $scope.CAT_MODE_STATIC = "svg";
        $scope.catMode = $scope.CAT_MODE_STATIC;
        $scope.done = false;
        $scope.started = false;
        $scope.meterWidth = 0;

        $scope.start = function() {
        	$scope.started = true;
          $scope.updateState();
        };

    		$scope.onClickCat = function(event) {
          if(timeout) return;
          
          $scope.meterWidth = Math.min($scope.meterWidth + 10,100);
          $scope.catMode = $scope.CAT_MODE_ANIM;
          timeout = $timeout(resetAnimation,2000);
          $scope.updateState();
        };

        $scope.complete = function() {
          $scope.economy.coping += 3;
        	$scope.setCurrentGame(null,"coping",3);
          $scope.updateState();
        };

        $scope.getPetSound = function() {
          if($scope.meterWidth === 100) return "barfill";
          if(($scope.meterWidth/10) % 2 === 1) return "meow2";
          return "purr";
        };

        function resetAnimation() {
          timeout = null;
          $scope.catMode = $scope.CAT_MODE_STATIC;
          $scope.updateState();
        }
      }  
    };
  });