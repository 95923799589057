(function () {'use strict';}());
angular.module("app").directive('gamesView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/games.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;

        $scope.getLoopAudio = function(game) {
        	if(game.id === 1) return "underwater";
          if(game.id === 13) return "beach";
          if(game.id === 4) return "waterfall";
          if(game.id === 14) return "cafe";
          if(game.id === 15) return "cityscape";
        	return null;
        };

        $scope.allGamesComplete = function() {
          var games = $scope.games;
          for(var i = 0, count = games.length; i < count; i++) {
            var game = games[i];
            if(!game.complete) return false;
          }
          return true;
          
        };
        
      }  
    };
  });