(function () {'use strict';}());
angular.module("app").directive('audio', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/audio.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;

        $scope.$watch("currentPage",onPageUpdate);
        $scope.$on("currentPageStateChange",onPageStateChange);
        $scope.$on("closeLoops",onCloseLoops);
        $scope.$on("setAutoPlayedSim",setAutoPlayedSim);
        $scope.$on("setAutoPlayed",setAutoPlayed);
        $scope.$on("setBubbleDone",setBubbleDone);
        $scope.$on("stopAudio",stopAudio);

        $scope.setPlayAudio(directPlay,directPlayLoop,stopAudio);

        $scope.autoAudioPlayed = false;
        $scope.autoPlayedQuiz23Stem = false;
        $scope.autoPlayedBubbleDone = false;
        // $scope.autoAudioPlayedSim = false;

        function onPageUpdate(value) {
            // console.log('page update');
            // console.log('cur page: ', $scope.currentPage);
        	var $clicks = $document.find("[data-click-audio]");
        	var $changes =  $document.find("[data-change-audio]");
            var $loops = $document.find("[data-loop-audio]");
            var $checklist = document.getElementById("checklist");
            var $sim = document.getElementById("sim");
            var $autoplay;
            var $autoplaySim;

            // console.log("current auto vo: ", $scope.currentAutoVO.volume);
            if($scope.currentAutoVO.volume == 1)
            {
                $autoplay = $document.find("[data-auto-audio]");
                $autoplaySim = $document.find("[data-auto-audio-sim]"); 
                $autoplay.each(assignAutoHandler);
                $autoplaySim.each(assignAutoHandler);
                // console.log("added auto handlers");
            }
            else{
                // console.log("did NOT add auto handlers");
            }
            
            $clicks.each(assignClickHandler);
            $changes.each(assignChangeHandler);
            $loops.each(assignLoopHandler);

            // console.log('checklist? ', $checklist);
            // console.log('auto played checklist? ', $scope.autoAudioPlayed);
            //Autoplay ignored so checkboxes play expected data-click-audio
            if(typeof $autoplay != "undefined")
            {
                if(!$checklist)
                {
                    $scope.autoAudioPlayed = false;                
                }
                // console.log('auto played? ', $scope.autoAudioPlayed);
                // console.log('autoplay arr ', $autoplay);
                // console.log("autoplayed sim ", $scope.autoAudioPlayedSim);

                //Handles autoplay for scenarios
                if($autoplaySim.length > 0 && $scope.currentPage == $scope.PAGE_SIMULATION && !$scope.autoAudioPlayedSim)
                {
                    // console.log("play sim autoVO");
                    stopAudio();
                    simClip = $autoplaySim[$autoplaySim.length - 1].dataset.autoAudioSim;
                    playSound(simClip);
                    $scope.autoAudioPlayedSim = true;
                }
                // outro page
                else if($autoplay.length > 3 && $scope.currentPage == $scope.PAGE_OUTRO) 
                {
                    stopAudio();
                    introClip = $autoplay[2].dataset.autoAudio || $autoplay[2].dataset.clickAudio || $autoplay[2].dataset.changeAudio;
                    $autoplay.splice($autoplay.length - 1);
                    // console.log('autoplay time', introClip);
                    // console.log('autoplay arr ', $autoplay);
                    playSound(introClip);
                }
                // non welcome page
                else if (($autoplay.length > 3 && !$scope.autoAudioPlayed) || (($autoplay.length > 3) && !$scope.autoPlayedQuiz23Stem))
                {
                        if($scope.autoPlayedBubbleDone === false){
                            stopAudio();
                            var clip = $autoplay[$autoplay.length - 1].dataset.autoAudio || $autoplay[$autoplay.length - 1].dataset.clickAudio || $autoplay[$autoplay.length - 1].dataset.changeAudio;
                            playSound(clip);
                            $scope.autoAudioPlayed = true;
                            if($autoplay[$autoplay.length - 1].dataset.autoAudio == 'quiz23_stem1' || $autoplay[$autoplay.length - 1].dataset.autoAudio == 'quiz23_stem1_es')
                            {
                                $scope.autoPlayedQuiz23Stem = true;
                            }
                        }
                        // play bubble pop sfx after reaching goal (instead of bubble done VO)
                        if($autoplay[$autoplay.length - 1].dataset.autoAudio == 'intro_15' || $autoplay[$autoplay.length - 1].dataset.autoAudio == 'intro_15_es'){
                            $scope.autoPlayedBubbleDone = true;   
                        }             
                }
                // welcome page
                else{
                    var introClip = "";
                    if($scope.currentPage == $scope.PAGE_WELCOME)
                    {
                        stopAudio();
                        introClip = $autoplay[0].dataset.autoAudio || $autoplay[0].dataset.clickAudio || $autoplay[0].dataset.changeAudio;
                        console.log('autoplay time', introClip);
                        playSound(introClip);
                    }
                    else if($scope.currentPage == $scope.PAGE_WELCOME_2){
                        stopAudio();
                        introClip = $autoplay[1].dataset.autoAudio || $autoplay[1].dataset.clickAudio || $autoplay[1].dataset.changeAudio;
                        console.log('autoplay time', introClip);
                        playSound(introClip);
                    }
                    else if($scope.currentPage == $scope.PAGE_OUTRO){
                        stopAudio();
                        introClip = $autoplay[2].dataset.autoAudio || $autoplay[2].dataset.clickAudio || $autoplay[2].dataset.changeAudio;
                        console.log('autoplay time', introClip);
                        playSound(introClip);
                    }
                }
            }
        }

        // receives emit whenever a scenario is opened
        function setAutoPlayedSim() {
            $scope.autoAudioPlayedSim = false;
        }

        function setAutoPlayed() {
            $scope.autoAudioPlayed = false;
        }

        function setBubbleDone() {
            $scope.autoPlayedBubbleDone = false;
        }

        function onPageStateChange() {
        	$timeout(onPageUpdate,0);
        }

        function onCloseLoops() {
            $scope.currentAudioLoopMP3 = null;
            $scope.currentAudioLoopOGG = null;
            try {
                var audio = $("audio.loop").get(0);
                audio.pause();
            } catch(e) {

            }
        }

        function assignClickHandler() {
        	$(this).off("click",playSound);
        	$(this).on("click",playSound);
        }

        function assignChangeHandler() {
        	$(this).off("change",playSound);
        	$(this).on("change",playSound);
        }

        function assignAutoHandler() {
            $(this).off("load",playSound);
            $(this).on("load",playSound);
            $(this).off("change",playSound);
            $(this).on("change",playSound);
        }

        function assignLoopHandler() {
            $(this).off("click",playLoop);
            $(this).on("click",playLoop);
        }

        function directPlay(src) {
            var audio = $("audio.sfx").get(0);
            if(!src || $scope.currentAudioInfo.volume === 0) return;
            audio.volume = $scope.currentAudioInfo.volume == 1 ? 1 : 0;
            $scope.currentAudioMP3 = "sound/" + src + ".mp3";
            $scope.currentAudioOGG = "sound/" + src + ".ogg";
            audio.load();
        }

        function directPlayLoop(src) {
            var audio = $("audio.loop").get(0);
            if(!src || $scope.currentAudioInfo.volume === 0) return;
            audio.volume = $scope.currentAudioInfo.volume == 1 ? 1 : 0;
            $scope.currentAudioLoopMP3 = "sound/" + src + ".mp3";
            $scope.currentAudioLoopOGG = "sound/" + src + ".ogg";
            audio.load();
        }

        function playLoop() {
            var src = $(this).attr("data-loop-audio");
            var audio = $("audio.loop").get(0);
            if(!src || $scope.currentAudioInfo.volume === 0) return;
            audio.volume = $scope.currentAudioInfo.volume == 1 ? 1 : 0;
            $scope.currentAudioLoopMP3 = "sound/" + src + ".mp3";
            $scope.currentAudioLoopOGG = "sound/" + src + ".ogg";
            $scope.$apply();
            audio.load();
        }

        function playSound(source) {
        	var src = $(this).attr("data-click-audio") || $(this).attr("data-change-audio");
            if (source && source.length > 0) {
                // console.log('override sound', source);
                src = source;
            }
            // console.log('play sound', src);
        	var audio = $("audio.sfx").get(0);
            // console.log('attempting audio', src);
            if(!src || $scope.currentAudioInfo.volume === 0) return;
            audio.volume = $scope.currentAudioInfo.volume == 1 ? 1 : 0;
        	$scope.currentAudioMP3 = "sound/" + src + ".mp3";
        	$scope.currentAudioOGG = "sound/" + src + ".ogg";

            //$scope.$apply(); returns error
            if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') {
                $scope.$apply();
            }

        	audio.load();
            // console.log('playing audio', src);

            // return false;
        }

        function stopAudio() {
            var audio;
            try {
                audio = $("audio.loop").get(0);
                audio.pause();
            } catch(e) {

            }

            try {
                audio = $("audio.sfx").get(0);
                audio.pause();
            } catch(e) {

            }
        }

      }
    };
  });