angular.module('app').filter('gtky', function() {
    return function(listing,gtky) {
    	if(!listing) return listing;
        var trace = angular.trace;
        var results = [];

        for(var i = 0, count = listing.length; i < count; i++) {
            var item = listing[i];
            if(gtky === true && item.questions[0].tier === 0) results.push(item);
            else if(!gtky && item.questions[0].tier !== 0) results.push(item);
        }
    	return results;
    };
});