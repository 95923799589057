(function () {'use strict';}());
angular.module("app").directive('aboutYouView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/about-you.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;

        $scope.getTitle = function(quiz) {
        	var questions = quiz.questions.filter(hasFeedback);
          // console.log('quiz', quiz);
        	if(!questions.length) return null;
        	var feedback = questions[0].feedback.presented['text_' + $scope.language];
          // console.log('title', questions[0]);
          var match = feedback.match(/^(.*?)(:.*?)$/gim);
          if(!match) return "Untitled";
        	return feedback.replace(/^(.*?)(:.*?)$/gim,"$1");
        };

        $scope.getFeedback = function(quiz) {
        	var questions = quiz.questions.filter(hasFeedback);
        	if(!questions.length) return null;
        	var feedback = "";

        	for(var i = 0, count = questions.length; i < count; i++) {
        		var question = questions[i];

            feedback += ("<div class=\"audio-block\">");
            feedback += ("<button class=\"audio-play-shifted\" type=\"button\" data-click-audio=\"" +  question.feedback.presented['textVO_' + $scope.language] + "\"><img src=\"../images/global/icon_playaudio.png\"></button>");
            feedback += ("<p>" + question.feedback.presented['text_' + $scope.language] + "</p>");
            feedback += ("</div>");
          }
        	return feedback;
         };

        function hasFeedback(e) {
        	if(e.feedback.presented) return true;
        	return false;
        }

      }
    };
  });