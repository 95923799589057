(function () {'use strict';}());
angular.module("app").directive('simulationIntroView', function($document,$timeout, AppService) {
    return {
      restrict: 'A',
      templateUrl: 'views/simulation-intro.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        // $scope.simIntroVO = $scope.textLookup.scenario_intro['vo_' + $scope.language];


        $scope.getNum = function(scenario) {
          if(!AppService.simulations) return null;
          var index = AppService.simulations.indexOf(scenario);
          var num = index +1;
          if(num.toString().length > 1) return num;
          else return "0"+num;
        };

        $scope.getAudioButton = function(){
          var feedback = "";
          feedback += ("<button type=\"button\" class=\"audio-play\" data-click-audio=\"" + $scope.textLookup.scenario_intro['vo_' + $scope.language] + "\" data-auto-audio=\"" + $scope.textLookup.scenario_intro['vo_' + $scope.language] + "\">");
          feedback += ("<img src=\"../images/global/icon_playaudio.png\">");
          feedback += ("</button>");
          // var feedback = $scope.textLookup.scenario_intro['vo_' + $scope.language];

          return feedback;
        };
        
      }  
    };
  });