(function () {'use strict';}());
angular.module("app").directive('relax', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/stress-reliever/relax.html',

      link:function($scope,$element,attr)
      {
        $scope.updateState();

        var trace = angular.trace;
        var timeout;
        var images = [
        {src:"images/games/relax/Waterfall.jpg",alt:"waterfall in verdant jungle"},
        {src:"images/games/relax/Beach.jpg",alt:"beach scene with waves and footprints in sand"},
        {src:"images/games/relax/fireplace.jpg",alt:"fireplace"},
        {src:"images/games/relax/CitySkyline.jpg",alt:"city skyline"}];

        $scope.done = false;
        $scope.started = false;
        $scope.STATE_STATIC = "static";
        $scope.STATE_ANIM = "anim";
        $scope.currentState = $scope.STATE_STATIC;
        $scope.currentImage = null;

        switch($scope.currentGame) {
          case $scope.GAME_RELAX_1:
            $scope.currentImage = images[0];
            break;
          case $scope.GAME_RELAX_2:
            $scope.currentImage = images[1];
            break;
          case $scope.GAME_RELAX_3:
            $scope.currentImage = images[2];
            break;
          case $scope.GAME_RELAX_4:
            $scope.currentImage = images[3];
            break;
          default: 
            $scope.currentImage = images[0];
        }

        $scope.start = function() {
        	$scope.started = true;
          $scope.currentState = $scope.STATE_ANIM;
          $scope.playAudioLoop($scope.getLoopAudio({id: $scope.currentGame || $scope.currentActivity.value}));
        };

        $scope.stop = function() {
          $scope.done = true;
          $scope.currentState = $scope.STATE_STATIC;
          $scope.stopAudio();
          $scope.updateState();
        };

        $scope.complete = function() {
          $scope.economy.coping += 2;
        	$scope.setCurrentGame(null,"coping",2);
        };

        function resetAnimation() {
          timeout = null;
          $scope.catMode = $scope.CAT_MODE_STATIC;
        }
      }  
    };
  });