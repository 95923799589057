(function () {'use strict';}());
angular.module("app").directive('userSummaryView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/user-summary.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        
        $scope.print = function() {
        	window.print();
        };

        $scope.getDownloadContent = function() {
        	save("my-project-here-info.txt","username: " + $scope.userInfo.username + "\npassword: " + $scope.userInfo.password);
        };

        function save(filename, data) {
		    var blob = new Blob([data], {type: 'text/csv'});
		    if(window.navigator.msSaveOrOpenBlob) {
		        window.navigator.msSaveBlob(blob, filename);
		    }
		    else{
		        var elem = window.document.createElement('a');
		        elem.href = window.URL.createObjectURL(blob);
		        elem.download = filename;        
		        document.body.appendChild(elem);
		        elem.click();        
		        document.body.removeChild(elem);
		    }
		}
      }  
    };
  });