(function () {'use strict';}());
angular.module("app").directive('pufferfish', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/stress-reliever/pufferfish.html',

      link:function($scope,$element,attr)
      {
        var trace = angular.trace;
        $scope.FISH_MODE_ANIM = "gif";
        $scope.FISH_MODE_STATIC = "svg";
        $scope.fishMode = $scope.FISH_MODE_STATIC;
        $scope.done = false;

        $scope.start = function() {
        	$scope.fishMode = $scope.FISH_MODE_ANIM;
          $scope.updateState();
        };

    		$scope.stop = function() {
    			$scope.done = true;
          $scope.fishMode = $scope.FISH_MODE_STATIC;
          $scope.updateState();
        };  

        $scope.complete = function() {
          $scope.economy.coping += 3;
        	$scope.setCurrentGame(null,"coping",3);
          $scope.updateState();
        };
      }  
    };
  });